import React from "react"
import { Link } from "gatsby"
import { RiArrowLeftSLine, RiBugLine, RiSkullLine } from "react-icons/ri"

import Seo from "../components/seo"
import Layout from "../components/layout"

const NotFound = () => (
  <Layout className="not-found-page">
    <Seo title="Page not found" />
    <div
      className="wrapper"
      style={{
        textAlign: "center",
      }}
    >
      <header>
        <RiSkullLine
          style={{
            fontSize: "128px",
            color: "var(--primary-color)",
          }}
        />
        <h1>Oops we did not expect this. <br/> あれ？！ここには何もないですね。</h1>
        <p>
          Have you wondered into the unknown? Let us help you, Please take a look
          at below options.<br/>道迷いました？もし何かおかしいと思うなら下のボタンで知らせてください。
        </p>
      </header>
      <Link to="/" className="button">
        <RiArrowLeftSLine className="icon -left" />
        Back to Homepage
      </Link>
      <a href={`mailto:l@jae.ac?subject=404 found&body=Hey I 've met 404.`} className="button">Report Now</a>
    </div>
  </Layout>
)

export default NotFound
